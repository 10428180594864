@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400&display=swap');

:root {
    --top-height: calc(30px + 40px + 8px + 25px);
    --content-height: calc(100vh - var(--top-height) - 20px);
    --content-section-height: calc(var(--content-height) - 40px);

    --color-primary: #2DCDAA;
    --color-primary-05: rgba(45, 205, 170, 0.05);
    --color-primary-10: rgba(45, 205, 170, 0.10);
    --color-primary-25: rgba(45, 205, 170, 0.25);
    --color-primary-50: rgba(45, 205, 170, 0.50);
    --color-primary-60: rgba(45, 205, 170, 0.60);
    --color-primary-75: rgba(45, 205, 170, 0.75);
    --color-primary-125: rgb(35, 165, 135);
    --color-primary-150: rgb(25, 125, 100);
    --color-primary-175: rgb(15, 85, 65);

    --text-body-color: #444;
    --text-alt-body-color: #525252;
    --background-color: #F5F5F5;
    --label-form-color: #888;
    --modal-content-color: #FFF;
    --card-background-color: #FFF;
    --card-title-color: #444;

    --color-red: #FF6347;
    --color-green: #43A047;
    --color-blue: #2196F3;
    --color-purple: #6735BC;
    --color-purple-light: #8159D8;
    --color-purple-dark: #4B24A0;
    --color-yellow: #E7D827;
    --color-yellow-light: #FCEC59;
    --color-yellow-dark: #CCB21B;

    /* https://codepen.io/sosuke/pen/Pjoqqp */
    --filter-white: invert(100%) sepia(100%) saturate(18%) hue-rotate(239deg) brightness(104%) contrast(100%);
    --filter-green: invert(71%) sepia(82%) saturate(2428%) hue-rotate(117deg) brightness(90%) contrast(94%);
}

.dark-mode {
    --color-primary: #2DCDAA;

    --text-body-color: rgb(190, 194, 198);
    --text-alt-body-color: rgb(188, 192, 196);
    --background-color: rgb(52, 60, 75);
    --label-form-color: rgb(249, 249, 249);
    --modal-content-color: rgb(38, 44, 57);
    --card-background-color: rgb(38, 44, 57);
    --card-title-color: rgb(255, 255, 255);

    --filter-green: invert(51%) sepia(74%) saturate(434%) hue-rotate(116deg) brightness(90%) contrast(85%);
}

.dark-mode img.undraw {
    filter: brightness(0.7) contrast(1.2) saturate(0.8) hue-rotate(-10deg);
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #BABABA;
}

::-webkit-scrollbar-thumb:hover {
    background: #9E9E9E;
}

* {
    scrollbar-color: #BABABA transparent !important;
    scrollbar-width: thin !important;
}

/* Scrollbar */

/* Tipografia */

* {
    font-family: 'Open Sans', sans-serif;
    color: var(--text-body-color);
}

i {
    color: unset;
}

.text-primary {
    color: var(--color-primary) !important;
}

h1.title-section {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1px;
    padding-bottom: 5px;
    margin: 0 1rem 23px 1rem !important;
    border-bottom: 1px solid #bababa;
    color: var(--text-alt-body-color);
}

h2.sub-title {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    padding-bottom: 5px;
    margin: 0 1rem 23px 1rem !important;
    color: var(--label-form-color);
}

.dark-mode h1.title-section {
    color: #FFF;
}

/* Tipografia */

/* Formulário */

label {
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 3px !important;
    text-transform: uppercase;
    color: var(--label-form-color);
}

input,
textarea {
    padding: 0 !important;
    background: transparent !important;
}

input,
select,
textarea {
    display: block;
    width: 100%;
    height: 28px !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 2px solid var(--color-primary) !important;
    color: var(--text-body-color) !important;
}

textarea {
    height: auto !important;
}

input:focus,
select:focus,
textarea:focus {
    border-color: var(--color-primary-150) !important;
    box-shadow: none !important;
    outline: none;
}

input.is-invalid {
    border-color: #F44336 !important;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

input[type="checkbox"]~.form-check-label,
input[type="checkbox"]~.custom-control-label {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 5px 0 0 10px;
    color: var(--label-form-color);
}

input[type="checkbox"]~.custom-control-label {
    line-height: 23px;
    margin: 0;
    cursor: pointer;
}

input[type="checkbox"]:focus {
    display: none;
}

input[type="checkbox"]:checked~.custom-control-label::before {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
}

input[type="range"] {
    border: none !important;
}

.custom-range::-webkit-slider-thumb {
    background: var(--color-primary);
}

.custom-range::-moz-range-thumb {
    background: var(--color-primary);
}

.custom-range::-ms-thumb {
    background: var(--color-primary);
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: var(--color-primary);
}

.custom-select {
    padding: 0 1.75rem 0 .75rem;
}

/* Formulário */

/* Botões */

.btn {
    font-weight: 600;
    border-width: 2px;
    padding: 5px 12px;
    border-radius: 10px;
}

.btn-primary {
    color: #FFF;
    border-color: var(--color-primary);
    background-color: var(--color-primary);
}

.dark-mode .btn-primary {
    color: var(--background-color);
}

.btn-primary:hover {
    color: #FFF;
    border-color: var(--color-primary-125);
    background-color: var(--color-primary-125);
}

.btn-primary:focus,
.btn-primary.focus {
    color: #FFF;
    border-color: var(--color-primary-125);
    background-color: var(--color-primary-125);
    box-shadow: none !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #FFF;
    border-color: var(--color-primary-75);
    background-color: var(--color-primary-75);
}

.dark-mode .btn-primary.disabled,
.dark-mode .btn-primary:disabled {
    color: var(--background-color);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #FFF;
    border-color: var(--color-primary-125);
    background-color: var(--color-primary-125);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-outline-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-outline-primary:hover {
    color: #FFF;
    border-color: var(--color-primary);
    background-color: var(--color-primary);
}

.dark-mode .btn-outline-primary:hover {
    color: var(--background-color);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: none !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--color-primary-75);
    border-color: var(--color-primary-75);
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #FFF;
    border-color: var(--color-primary-125);
    background-color: var(--color-primary-125);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-outline-secondary {
    color: #6C757D !important;
    border-color: #6C757D !important;
}

.btn-outline-secondary:hover {
    color: #FFF !important;
}

.btn-info {
    color: #FFF !important;
    border-color: var(--color-purple);
    background-color: var(--color-purple);
}

.btn-info:hover {
    color: #FFF;
    border-color: var(--color-purple-dark);
    background-color: var(--color-purple-dark);
}

.btn-info:focus,
.btn-info.focus {
    color: #FFF;
    border-color: var(--color-purple-dark);
    background-color: var(--color-purple-dark);
    box-shadow: none !important;
}

.btn-info.disabled,
.btn-info:disabled {
    color: #FFF;
    border-color: var(--color-purple-light);
    background-color: var(--color-purple-light);
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #FFF;
    border-color: var(--color-purple-dark);
    background-color: var(--color-purple-dark);
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-outline-info {
    color: var(--color-purple) !important;
    border-color: var(--color-purple);
}

.btn-outline-info:hover {
    color: #FFF !important;
    border-color: var(--color-purple);
    background-color: var(--color-purple);
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: none !important;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: var(--color-purple-light);
    border-color: var(--color-purple-light);
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #FFF;
    border-color: var(--color-purple-dark);
    background-color: var(--color-purple-dark);
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-warning {
    color: #161930 !important;
    border-color: var(--color-yellow);
    background-color: var(--color-yellow);
}

.btn-warning:hover {
    color: #161930 !important;
    border-color: var(--color-yellow-dark);
    background-color: var(--color-yellow-dark);
}

.btn-warning:focus,
.btn-warning.focus {
    color: #161930;
    border-color: var(--color-yellow-dark);
    background-color: var(--color-yellow-dark);
    box-shadow: none !important;
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #161930;
    border-color: var(--color-yellow-light);
    background-color: var(--color-yellow-light);
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #161930;
    border-color: var(--color-yellow-dark);
    background-color: var(--color-yellow-dark);
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-outline-warning {
    color: var(--color-yellow) !important;
    border-color: var(--color-yellow);
}

.btn-outline-warning:hover {
    color: #161930 !important;
    border-color: var(--color-yellow);
    background-color: var(--color-yellow);
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: none !important;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: var(--color-yellow-light);
    border-color: var(--color-yellow-light);
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #161930;
    border-color: var(--color-yellow-dark);
    background-color: var(--color-yellow-dark);
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: none !important;
}

/* Botões */

/* Card */

.card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(32, 32, 32, 0.1);
    background-color: var(--card-background-color);
    transition: .3s;
}

.card p.title {
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: -8px 0 5px 0px;
    opacity: 0.8;
    color: var(--card-title-color);
}

.dark-mode .card p.title {
    opacity: 1;
}

.card p.title>i {
    position: absolute;
    top: 0;
    line-height: 18px;
    opacity: .5;
    cursor: pointer;
    transition: .3s;
}

.card p.title>i:hover {
    opacity: 1;
    transition: .3s;
}

.card p.title>i:first-child {
    right: 125px;
}

.card p.title>i:nth-last-child(2) {
    right: 30px;
}

.card p.title>i:last-child {
    right: 0;
}

.card p.title>span {
    position: absolute;
    top: -1px;
    font-size: 11px;
    line-height: 18px;
    color: var(--color-primary-125);
    cursor: default;
}

.card p.title>span:nth-child(2) {
    right: 81px;
}

.card p.title>span:nth-child(2)::after {
    position: absolute;
    top: 0;
    right: -18px;
    content: '~';
}

.card p.title>span:nth-child(3) {
    right: 18px;
}

/* Card */

/* Modal */

.modal-sm {
    max-width: 430px !important;
}

.modal-content {
    border-radius: 10px;
    background-color: var(--modal-content-color);
}

.modal-header {
    border: none;
    padding: 30px 28px;
}

.modal-header h5 {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0;
    text-transform: none;
    margin: 0;
    color: var(--text-alt-body-color);
}

.modal-header>.close {
    padding: 0;
    margin: -10px 0 0 0;
    user-select: none;
}

.modal-header>.close>span {
    font-size: 40px;
    color: var(--color-primary);
}

.dark-mode .modal-header>.close>span {
    color: var(--label-form-color);
}

.modal-body {
    padding: 0 30px;
}

.modal-footer {
    padding: 30px;
    border: none;
}

.modal-footer>button {
    width: 130px;
    margin: 0 0 0 15px;
}

/* Modal */

/* Dropdown */

.dropdown-menu {
    border-radius: 10px;
    border-color: transparent;
    background-color: var(--card-background-color);
    box-shadow: 0 4px 6px rgba(32, 32, 32, 0.1);
}

.dropdown-item {
    transition: .3s;
}

.dropdown-item:hover {
    background-color: var(--color-primary-25);
    transition: .3s;
}

/* Dropdown */

/* Progress */

.progress {
    border-radius: 10px !important;
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.20) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .20) 50%, rgba(0, 0, 0, .20) 75%, transparent 75%, transparent);
}

/* Progress */

/* Typeahead */

ngb-typeahead-window {
    max-width: 500px;
    max-height: 215px;
    overflow-y: auto;
}

ngb-typeahead-window>button {
    max-width: 500px;
    white-space: break-spaces !important;
}

/* Typeahead */

/* ApexCharts */

.apexcharts-pie-series path:not(.apexcharts-donut-solid) {
    stroke-width: 2px;
    stroke: var(--card-background-color);
    transition: .3s;
}

/* ApexCharts */

/* Tooltip e Popover */

.tooltip-lg>.tooltip-inner {
    max-width: 60vw;
    text-align: justify;
    padding: 25px;
}

.popover-links>.popover-body {
    width: 250px;
    padding: 20px 25px;
    background-color: #000;
}

.popover-links>.arrow::after {
    border-left-color: #000;
}

.popover-links>.popover-body>p,
.popover-links>.popover-body>p>* {
    margin-bottom: 0;
    color: #FFF !important;
}

/* Tooltip e Popover */

/* Badge */

.category-badge {
    width: 128px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFF;
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    overflow: hidden;
    text-overflow: ellipsis;
}

.badge.STUDY,
.badge.REVIEW,
.badge.EXAM_QUESTIONS,
.badge.READING,
.badge.JURISPRUDENCE,
.badge.EXAM {
    width: 128px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #FFF;
}

.badge.EXAM {
    text-transform: uppercase;
    background-color: var(--color-blue);
}

.badge.STUDY {
    text-transform: uppercase;
    background-color: #7E57C2;
}

.badge.REVIEW {
    text-transform: uppercase;
    background-color: #F44336;
}

.badge.EXAM_QUESTIONS {
    text-transform: uppercase;
    background-color: #66BB6A;
}

.badge.READING {
    text-transform: uppercase;
    background-color: #FFB300;
}

.badge.JURISPRUDENCE {
    text-transform: uppercase;
    background-color: #FF8A65;
}

/* Badge */

/* Tour */

.guided-tour-spotlight-overlay {
    z-index: 9999;
    border-radius: 10px;
    border: 3px solid var(--color-primary);
}

/* .tour-arrow::before {
    position: absolute;
    transform: translate(-50%);
    left: 50%;
    width: 0;
    height: 0;
    content: "";
    z-index: 2;
    border-bottom: 1rem solid #ffffff;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
} */

.tour-block {
    border-radius: 10px;
    color: var(--text-body-color);
    background-color: var(--card-background-color);
    box-shadow: 0 4px 6px rgba(32, 32, 32, 0.1);
}

.tour-step {
    z-index: 10000;
    text-align: justify;
}

.tour-buttons .next-button {
    font-weight: 600;
    border-radius: 5px !important;
    color: #FFF;
    background-color: var(--color-primary);
}

.dark-mode .tour-buttons .next-button {
    color: var(--background-color);
}

/* Tour */

/* Planos Arquivados */

.archived-plan {
    opacity: 0.2 !important;
    cursor: default !important;
}

.archived-plan:hover {
    opacity: 0.2 !important;
}

/* Planos Arquivados */

/* Tooltip gráfico de evolução no tempo */

.question-performance-chart-tooltip {
    padding: 12px;
    margin: 0px;
}

.question-performance-chart-tooltip>li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
}

.question-performance-chart-tooltip>li>div.series-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
}

.question-performance-chart-tooltip>li:first-child {
    background-color: rgb(236, 239, 241);
    border-bottom: solid 1px rgb(221, 221, 221);
    margin: -12px -12px 0 -12px;
    padding: 8px;
}

.question-performance-chart-tooltip>li:nth-child(2) {
    margin-top: 8px;
}

.question-performance-chart-tooltip>li:nth-child(3) {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px rgb(200, 200, 200);
}

.question-performance-chart-tooltip>li:nth-child(2)>div.series-dot {
    background-color: var(--color-primary);
}

.question-performance-chart-tooltip>li:nth-child(3)>div.series-dot {
    background-color: var(--color-purple);
}

/* Tooltip gráfico de evolução no tempo */